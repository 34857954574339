//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      treeHoles: []
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getTreeHoles();
  },
  mounted: function mounted() {},
  methods: {
    getTreeHoles: function getTreeHoles() {
      var _this = this;

      this.$http.post(this.$constant.baseURL + "/admin/treeHole/boss/list", this.pagination, true).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.treeHoles = res.data.records;
          _this.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getTreeHoles();
    },
    handleDelete: function handleDelete(item) {
      var _this2 = this;

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this2.$http.get(_this2.$constant.baseURL + "/webInfo/deleteTreeHole", {
          id: item.id
        }, true).then(function (res) {
          _this2.pagination.current = 1;

          _this2.getTreeHoles();

          _this2.$message({
            message: "删除成功！",
            type: "success"
          });
        }).catch(function (error) {
          _this2.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    }
  }
};